.selection {
    max-width: 50%;
    margin: auto;
}

.player {
    display: flex;
    justify-content: center;
}

.selection button {
    box-shadow: 0px 0px 2px 2px rgb(0,0,0);
    margin: 5px;
    border: none;
    border-radius: 1rem;
    background-color: #ae0f50;
    color: white;
    min-height: 30px;
    min-width: 120px;
}

.selection button:hover {
    background-color: black;
    transition: 0.7s ease-in;
    cursor: pointer;

} 

.selection button:active {
    background-color:white;
    color:#ae0f50;
    transition:0.2s;
    
}

h2 {
    margin-top: 50px;
}

img {
    max-height: 200px;
}

@media only screen and (max-width: 600px) {
    .selection button{
        
        min-width: 25px;
        margin: 1px;
    }
}
