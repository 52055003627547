
.guide {
    text-align: left;
    margin-top: 60px;
    display: flex;
    flex-direction: column;
    justify-content: center;
}
.guide img{
    max-width: 160px;
    max-height: 308px;
    height: auto;
    width: auto;
    margin-left: 10px;
}

h2 {
    margin-left: 10px;
}

.react-tabs {
    -webkit-tap-highlight-color: transparent;
  }
  
  .react-tabs__tab-list {
    border-bottom: 1px solid #aaa;
    border-radius: 1rem;
    margin: 0 10px 10px;
    padding: 0;
    width: 100%;
  }
  
  .react-tabs__tab {
    display: inline-block;
    border: 1px solid transparent;
    border-bottom: none;
    bottom: -1px;
    position: relative;
    list-style: none;
    padding: 6px 12px;
    cursor: pointer;
  }
  
  .react-tabs__tab--selected {
    background: #fff;
    border-color: #aaa;
    color: #ae0f50;
    border-radius: 1rem 1rem 0 0;
  }
  
  .react-tabs__tab--disabled {
    color: gray;
    cursor: default;
  }
  
  .react-tabs__tab:focus {
    outline: none;
  }
  
  .react-tabs__tab:focus:after {
    content: '';
    position: absolute;
    height: 5px;
    left: -4px;
    right: -4px;
    bottom: -5px;
    background: #fff;
  }
  
  .react-tabs__tab-panel {
    display: none;
  }
  
  .react-tabs__tab-panel--selected {
    display: block;
    background-color: rgba(0, 0, 0, 0.5);
    border: 2px solid white;
    border-radius: 1rem;
    margin: 0 10px;
    padding: 10px;
  }

  q {
    font-style: italic;
    font-size: 18px;
    

  }

  p {
    font-size: 20px;
    max-width: 1000px;
    text-indent: 20px;
  }

  ul {
    max-width: 1000px;
  }

  .player{
    margin: 5px auto;
  }

