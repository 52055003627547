.combo-list {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    justify-items: center;
    align-items: flex-start;
    width: 800px;
    background-color: rgba(0, 0, 0, 0.5);
    border: 2px solid white;
    border-radius: 1rem;
    margin: 0 10px;
}

@media only screen and (max-width: 900px) {
    .combo-list{
        
        width: 85vw;
        
    }
}