
   
.main {
    text-align: center;
    margin: 50px auto 50px;
    
  }
  .selection-box {
    height: 100%;
    width: 250px;
    display: inline-flex;
    flex-direction: column;
    align-items: center;
    padding: 2px;
    background-color: rgba(0, 0, 0, 0.5);
    border: 2px solid white;
    border-radius: 1rem;
    margin: 0 10px;


  }   



.selection-box button {
    box-shadow: 0px 0px 2px 2px rgb(0,0,0);
    margin: 5px;
    border: none;
    border-radius: 1rem;
    background-color: #ae0f50;
    color: white;
    min-height: 30px;
    min-width: 120px;
}

.selection-box button:hover {
    background-color: black;
    transition: 0.7s ease-in;
    cursor: pointer;
}

.selection-box button:active {
    background-color:white;
    color:#ae0f50;
    transition:0.2s;
}