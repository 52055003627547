.pagination {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  
  .paginationItem {
    box-shadow: 0px 0px 2px 2px rgb(0,0,0);
    border: none;
    border-radius: 1rem;
    background-color: #ae0f50;
    color: white;
    height: 21px;
    width: 35px;
    position: relative;
    margin: 3px 1px;
    cursor: pointer;
  }
  
  .paginationItem span {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
  
  .prev,
  .next {
    box-shadow: 0px 0px 2px 2px rgb(0,0,0);
    height: 25px;
    margin: 3px;
    border: none;
    border-radius: 1rem;
    background-color: #ae0f50;
    color: white;
    cursor: pointer;
  }
  
  .paginationItem.active {
    box-shadow: 0px 0px 2px 2px rgb(0,0,0);
    border: none;
    border-radius: 1rem;
    background-color: white;
    color: black;
    pointer-events: none;
  }
  
  .prev.disabled,
  .next.disabled {
    pointer-events: none;
    box-shadow: 0px 0px 2px 2px rgb(0,0,0);
    margin: 5px;
    border: none;
    border-radius: 1rem;
    background-color: gray;
    color: white;
  }

  @media only screen and (max-width: 600px) {
    .paginationItem {
      
      height: 15px;
      width: 20px;
      
    }
    .prev,
    .next {
      height: 15px;
      
    }
  }