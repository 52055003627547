.video-selection button{
    box-shadow: 0px 0px 2px 2px rgb(0,0,0);
    margin: 5px;
    border: none;
    border-radius: 1rem;
    background-color: #ae0f50;
    color: white;
    min-width: 50px;
    
}
h2 {
    margin-top: 50px;
}

button img {
    max-height: 90px;
}

.video-selection {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    justify-items: center;
}

.player {
    display: flex;
    justify-content: center;
}


.video-selection button:hover {
    background-color: black;
    transition: background-color 0.7s ease-in;
    cursor: pointer;

} 

.video-selection button:active {
    background-color:white;
    color:#ae0f50;
    transition: 0.2s;
    
}

@media only screen and (max-width: 600px) {
    .video-selection button{
        
        min-width: 25px;
        margin: 1px;
        
    }
}