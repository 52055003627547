.App {
  text-align: center;
  background-color: black;
  background-image: url("../public/background.jpg");
  background-size: cover;
  background-repeat: no-repeat;
  background-attachment: fixed;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: 1.5rem;
  color: white;
}


@media only screen and (max-width: 600px) {
  .App {
  font-size: 1rem;
  }
}

