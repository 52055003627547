.nav {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    min-height: 2.5vw;
    position: fixed;
    top: 0px; 
    width: 100%;
    background-color: rgba(0, 0, 0, 1);
    z-index: 1;
    align-items: center;
}
.nav a {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    color:white;
    text-decoration: none;
    cursor: pointer;
    font-size: 1.5vw;
    align-items: center;
    padding-right: 0.75vw;
    padding-left: 0.75vw;

}

.linker {
    border-right: 2px solid white;
}



.nav img {
    display: flex;
    flex-direction: row;
    margin-right: 0.5vw;
    margin-left: 0.5vw;
    max-height: 2.5vw;
    min-height: 2.5vw;
    align-items: center;
}
.links {
    display: flex;

}
.links :hover{
    background-color:white;
    color: black;
    transition: all 0.7s ease-out;
}

.socials {
    display: flex;
}


@media only screen and (max-width: 900px) {
    .nav {
        min-height: 30px;
    }
    .nav img{
        min-height: 19px;
        margin: 0px;
        padding: 0px;
    }
    .socials {
        display: flex;
        flex-wrap: wrap;
        align-items: flex-start;
    }
    .nav a{
        
        font-size: 2.6vw;
        
    }

}