.video {
    height: 100%;
    width: 600px;
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
    align-items: center;
    padding: 2px;
    background-color: rgba(0, 0, 0, 0.5);
    border: 2px solid white;
    border-radius: 1rem;
    margin: 10px 10px;
    font-size: 14px;
}

@media only screen and (max-width: 600px) {
    .video{
        
        width: 80vw;
        
    }
}